import axios from '@axios'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    filterItems: null,
    industryOptions: [],
    businessRoleOptions: [],
    areaOptions: [],
    saleableMarketOptions: [],
    modeOptions: [],
    nationalityOptions: [],
    langOptions: [],
  },
  getters: {},
  mutations: {
    SET_COMPANY_LIST_FILTER: (state, filterObj) => {
      state.filterItems = filterObj;
    },
    SET_AREA_OPTIONS: (state, list) => {
      state.areaOptions = list;
    },
  },
  actions: {
    fetchCompanies(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/api/company/all', {
            headers: { Authorization: store.state.auth.authToken },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchCompany(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/api/company/id/${id}`, {
            headers: { Authorization: store.state.auth.authToken },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchAreaList() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/area', {
            headers: { Authorization: store.state.auth.authToken },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
