import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCompanyList() {
  // Use toast
  const toast = useToast()

  const refCompanyListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'company_id', label: 'ID', sortable: true },
    { key: 'company', label: 'Pic' },
    { key: 'name', label: 'Name', sortable: true},
    { key: 'slug', label: 'Slug', sortable: true },
    { key: 'hqLocation', label: 'HQ Location', sortable: true, formatter: (value, key, item) => {
        return value ? value.name : '-' }},
    { key: 'status', label: 'Status', sortable: true, formatter: (value, key, item) => {
      return value? 'Active' : 'Inactive'
    }},
    { key: 'created_at', label: 'Created at', sortable: true, formatter: (value, key, item) => {
      return value ? new Date(value).toLocaleString(): '-' }},
    { key: 'updated_at', label: 'Updated at', sortable: true, formatter: (value, key, item) => {
        return value ? new Date(value).toLocaleString(): '-' }},
    { key: 'actions' },
  ]
  const companyFullList = ref(null)
  const perPage = ref(10)
  const totalCompanies = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(null)
  const sortBy = ref('user_id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const areaFilter = ref(null)
  const filterOn = ref(["user", "name", "email", "primaryIndustry", "hqLocation"])
  const areaOptions = ref([])
  const searchFilters = ref ([])
  const companyFilterList = ref(null)

  const clearFilter = () => {
    searchQuery.value = null;
    sortBy.value = 'company_id';
    isSortDirDesc.value = true;
    statusFilter.value = null;
    areaFilter.value = null;
  }

  const { route } = useRouter();

  const dataMeta = computed(() => {
    const localItemsCount = refCompanyListTable.value ? refCompanyListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCompanies.value,
    }
  })

  onMounted(() => {
    // user list - has filter items
    if (route.value.name === 'apps-company-list' && store.state['app-company'].filterItems) {
      const filterItems = JSON.parse(JSON.stringify(store.state['app-company'].filterItems));
      if (filterItems.length > 0) {
        isSortDirDesc.value = filterItems[0].isSortDirDesc;
        searchQuery.value = filterItems[0].searchQuery;
        sortBy.value = filterItems[0].sortBy;
        statusFilter.value =  filterItems[0].status,
        areaFilter.value =  filterItems[0].location
      }
    }
  });

  const refetchData = () => {
    refCompanyListTable.value.refresh()
  }

  /*update search filter*/
  watch([statusFilter, areaFilter], () => {
    if (statusFilter.value == null && 
      (areaFilter.value == null || areaFilter.value.length == 0)) {
        searchFilters.value = null  
    } else {
      let searchObj = {};
      if (statusFilter.value !== null) {
        searchObj = {...searchObj, status: statusFilter.value};
      }
      if (areaFilter.value && areaFilter.value.length > 0) {
        searchObj = {...searchObj, hqLocation: areaFilter.value}
      }
      searchFilters.value = searchObj;
    }
  })

  // custom filter logic
  watch([companyFullList, searchFilters], () => {  
    if (searchFilters.value == null) {
      companyFilterList.value = companyFullList.value
    } else {
      if (companyFullList.value) {

        const filtered = companyFullList.value.filter(tableRow => {
          return Object.keys(searchFilters.value).every(colKey => {
            if (typeof(searchFilters.value[colKey]) == 'object') {
              if (tableRow[colKey]) {
                // filter with area id to avoid duplicate name
                if (colKey == 'hqLocation') {
                  return searchFilters.value[colKey].filter(v => tableRow[colKey]['area_id'] == v).length > 0
                } else {
                  return searchFilters.value[colKey].filter(v => tableRow[colKey]['name'] == v).length > 0
                }
              } else {
                return false
              }
            } else {
              return tableRow[colKey] == searchFilters.value[colKey]
            }
          })
        })
        
        companyFilterList.value = filtered
        totalCompanies.value = filtered.length
      }
    }
  })

  //table items provider
  const fetchCompanies = (ctx, callback) => {
    store
      .dispatch('app-company/fetchCompanies')
      .then(response => {
        const { companies } = response.data
        companyFullList.value = companies
        companyFilterList.value = companies
        totalCompanies.value = companies.length
        // callback(users)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching companies list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchAreaOptions = () => {
    if (store.state['app-company'].areaOptions.length == 0) {
      store
        .dispatch('app-company/fetchAreaList')
        .then((response) => {
          const { areas } = response.data
          const dataList = areas.map((item) => {
            return { label: item.name, value: item.area_id }
          })
          store.commit('app-company/SET_AREA_OPTIONS', dataList)
          areaOptions.value = dataList;      
        })
        .catch(() => {})
    }
    areaOptions.value = store.state['app-company'].areaOptions;
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchCompanies,
    fetchAreaOptions,
    companyFullList,
    companyFilterList,
    tableColumns,
    perPage,
    currentPage,
    totalCompanies,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refCompanyListTable,
    clearFilter,

    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    areaFilter,
    areaOptions,
    filterOn,
    searchQuery,
    searchFilters,
  }
}
